import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IEndPoint } from 'src/app/domain/interfaces/iendpoint';
import { InstalledApplicationToPackageLink } from 'src/app/domain/interfaces/installed-application-to-package-link';
import { Action } from 'src/app/modules/shared/domain/action';
import { ApplicationPackageLinkServiceService } from 'src/app/services/application-package-link-service.service';
import { NotifyMeComponent } from '../notify-me/notify-me.component';

@Component({
  selector: 'nd-endpoint-applications-tab',
  templateUrl: './endpoint-applications-tab.component.html',
  styleUrls: ['./endpoint-applications-tab.component.scss'],
})
export class EndpointApplicationsTabComponent implements OnInit, OnChanges {
  @ViewChild('cmp', { static: false })
  notificationComponent: NotifyMeComponent;
  @Input()
  endpoint: IEndPoint;
  @Input()
  packages: Action[] = [];
  links: { link: InstalledApplicationToPackageLink; selectedName?: string }[] =
    [];
  filteredPackages: Action[] = [];
  isLoadingLinks = true;
  isLoadingAcceptance = false;
  currentPage = 1;
  itemsPerPage = 10;
  count = 0;

  constructor(
    private applicationPackageLinkService: ApplicationPackageLinkServiceService
  ) {}

  ngOnInit(): void {
    this.fetchLinks();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.packages) {
      this._verifySelectedNamesOnLinks();
    }
  }

  fetchLinks(): void {
    this.isLoadingLinks = true;
    this.links = [];
    this.applicationPackageLinkService
      .getApplicationToPackageLinksForEndpoint(
        this.endpoint.id,
        (this.currentPage - 1) * this.itemsPerPage,
        this.itemsPerPage
      )
      .subscribe({
        next: (pagedData) => {
          this.links = pagedData.data.map((l) => ({ link: l }));
          this.count = pagedData.count;
          this._verifySelectedNamesOnLinks();
        },
        error: (err) => console.error(err),
        complete: () => {
          this.isLoadingLinks = false;
        },
      });
  }

  onChangePage(pageIndex: number): void {
    this.currentPage = pageIndex;
    this.fetchLinks();
  }

  onConfirmLink(link: InstalledApplicationToPackageLink): void {
    const selectedName = this.links.find(
      (l) => l.link.id === link.id
    ).selectedName;
    const selectedAction = this.packages.find((p) => p.name === selectedName);

    console.log({ selectedName, selectedAction, links: this.links });

    if (!selectedAction) {
      this.notificationComponent.showError('Package name is not valid.');
      return;
    }

    this.isLoadingAcceptance = true;
    link.actionId = selectedAction.id;

    this.applicationPackageLinkService
      .acceptApplicationToPackageLink(link)
      .subscribe({
        next: (_) => {
          link.isConfirmed = true;
        },
        error: (err) => {
          this.notificationComponent.showError('Failed to confirm.');
          console.error(err);
        },
        complete: () => {
          this.isLoadingAcceptance = false;
          this.notificationComponent.showSuccess('Successfully confirmed.');
        },
      });
  }

  onInputValueChanged(event: any): void {
    if (event.target.value ?? '' === '') this.filteredPackages = this.packages;
    this.filteredPackages = this._filter(event.target.value ?? '');
  }

  private _filter(value: string): Action[] {
    const filterValue = this._normalizeValue(value);
    return this.packages.filter((p) =>
      this._normalizeValue(p.name).includes(filterValue)
    );
  }

  private _normalizeValue(value: string): string {
    return value?.toLowerCase().replace(/\s/g, '');
  }

  private _verifySelectedNamesOnLinks(): void {
    this.links.map((l) => {
      if (l.link.actionId !== null) {
        l.selectedName = this.packages.find(
          (p) => p.id === l.link.actionId
        )?.name;
      }

      return l;
    });
  }
}
