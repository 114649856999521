import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '../domain/action';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/modules/shared/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { ActionDto } from '../domain/action-dto';
import { ActionAutoLifecycleConfigurationDto as ActionAutoLifecycleConfigurationDto } from '../domain/action-auto-lifecycle-configuration-dto';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getNameWithX86Appended(action: Action | ActionDto) {
    let name = action.name;
    const hasX86 = action.parameters.toLowerCase().includes('x86');
    if (hasX86) {
      name += ' (x86)';
    }
    return name;
  }

  getActions() {
    return this.http.get<Action[]>(`${this.URL}/Action`).pipe(
      map((x) => {
        const sorted = x
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((x) => ({ ...x, name: this.getNameWithX86Appended(x) }));
        return sorted;
      })
    );
  }

  getActionsWithoutRecycledVersions() {
    return this.http
      .get<Action[]>(`${this.URL}/Action?includeRecycled=false`)
      .pipe(
        map((x) => {
          const sorted = x
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((x) => ({ ...x, name: this.getNameWithX86Appended(x) }));
          return sorted;
        })
      );
  }

  GetCount(): Observable<number> {
    return this.http.get<number>(`${this.URL}/Action/count`);
  }

  GetActionDtos() {
    return this.http.get<ActionDto[]>(`${this.URL}/Action/all-packages`).pipe(
      map((x) => {
        const sorted = x
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((x) => ({ ...x, name: this.getNameWithX86Appended(x) }));
        return sorted;
      })
    );
  }

  getRecycledActionDtos(): Observable<ActionDto[]> {
    return this.http.get<ActionDto[]>(`${this.URL}/Action/recycled`).pipe(
      map((actions) => {
        const sorted = actions
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((a) => ({ ...a, name: this.getNameWithX86Appended(a) }));
        return sorted;
      })
    );
  }

  GetActionsForTag(tagId: number): Observable<Action[]> {
    return this.http.get<Action[]>(`${this.URL}/Action/forTag/${tagId}`).pipe(
      map((x) => {
        const sorted = x
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((x) => ({ ...x, name: this.getNameWithX86Appended(x) }));
        return sorted;
      })
    );
  }

  GetIcon(iconName): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(`${this.URL}/blobs/NimDeploy/Icons/${iconName}.png`, {
      headers: headers,
      responseType: 'blob',
      observe: 'response' as 'body',
    });
  }

  GetActionIcon(iconName) {
    var options = {
      headers: new HttpHeaders({
        'Content-Type': 'image/svg+xml',
      }),
    };
    return this.http.get(`${this.URL}/blobs/NimDeploy/Icons/${iconName}.png`);
  }

  getData(iconName): Observable<any> {
    return from(
      fetch(
        `${this.URL}/blobs/NimDeploy/Icons/${iconName}.svg`, // the url you are trying to access
        {
          headers: {
            'Content-Type': 'image/svg+xml',
            // 'dataType': 'image'
          },
          method: 'GET', // GET, POST, PUT, DELETE
          // mode: 'no-cors' // the most important option
        }
      )
    );
  }

  GetActionByID(id) {
    return this.http.get<Action>(`${this.URL}/Action/${id}`);
  }

  GetNewActions() {
    return this.http.get<Action>(`${this.URL}/Action/syncchocolatey`);
  }

  PostSingleAction(name, parameters, Actiontypeid) {
    var data = {
      name: name,
      parameters: parameters,
      ActionTypeId: parseInt(Actiontypeid),
    };
    return this.http.post<Action>(`${this.URL}/Action`, data);
  }

  UpdateSingleAction(id, name, parameters, actiontypeid) {
    var data = {
      id: parseInt(id),
      name: name,
      parameters: parameters,
      actionTypeId: parseInt(actiontypeid),
    };
    return this.http.put<Action>(`${this.URL}/Action`, data);
  }

  setIsDetachedInstall(actionId: number, isDetachedInstall: boolean) {
    return this.http.post<void>(
      `${this.URL}/Action/${actionId}/setDetachedInstall`,
      isDetachedInstall
    );
  }

  setExecutionTime(actionId: number, executionTime: number) {
    return this.http.post<void>(
      `${this.URL}/Action/${actionId}/setExecutionTime`,
      executionTime
    );
  }

  setIsRebootRequired(actionId: number, isRebootRequired: boolean) {
    return this.http.post<void>(
      `${this.URL}/Action/${actionId}/setRebootRequired`,
      isRebootRequired
    );
  }

  UpdateAction(data) {
    return this.http.put<Action>(`${this.URL}/Action/${data.id}`, data);
  }

  UpdateActionStatus(actions: number[], isActive) {
    return this.http.put<Action>(
      `${this.URL}/Action/status?isActive=${isActive}`,
      actions
    );
  }

  RemoveSingleActionByID(id) {
    return this.http.delete<Action>(`${this.URL}/Action/${id}`);
  }

  subscribeToAction(actionId: number): Observable<Object> {
    return this.http.post(`${this.URL}/tenant/toggle-subscribe/proxy`, {
      tenantName: this.localStorageService.tenantName ?? environment.tenantName,
      actionId: actionId,
    });
  }

  getActionsForTenant(): Observable<Action[]> {
    return this.http.get<Action[]>(`${this.URL}/action/for-tenant/proxy`, {
      params: new HttpParams().set(
        'tenantName',
        this.localStorageService.tenantName ?? environment.tenantName
      ),
    });
  }

  restoreAllActionVersionsForActionIds(actionIds: number[]): Observable<null> {
    return this.http.post<null>(
      `${this.URL}/action/restore-all-versions`,
      actionIds
    );
  }

  setAutoLifecycleConfigurationForAction(
    actionId: number,
    configuration: ActionAutoLifecycleConfigurationDto
  ): Observable<any> {
    return this.http.put(`${this.URL}/action/lifecycle/configuration/${actionId}`, configuration);
  }
}
