<clr-main-container #mainCont>
  <header class="header-6" *ngIf="currentUser">
    <div class="branding">
      <div *ngIf="isMsp">
        <clr-dropdown [clrCloseMenuOnItemClick]="true">
          <button clrDropdownTrigger>
            <cds-icon shape="switch" class="switch-icon"></cds-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen>
            <label class="dropdown-header">Switch workspace to</label>
            <div class="dropdown-divider" role="separator"></div>
            <div clrDropdownItem (click)="switchDashboard({toMsp: true})" routerLinkActive="active">MSP's Dashboard
            </div>
            <div *ngFor="let tenant of tenants; let i = index;" clrDropdownItem
              (click)="switchDashboard({tenantIndex: i})" routerLinkActive="active">
              {{tenant.name}}'s Dashboard
            </div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>
      <div *ngIf="!mspDashboard" routerLink="./dashboard" [class.nimble-logo]="isMsp">
        <a (click)="handleSaveModal($event, './dashboard')">
          <img src="assets/logo.svg" width="143px" alt="logo">
        </a>
      </div>
      <div *ngIf="isMsp && !mspDashboard" class="tenant-name">
        {{ currentTenantName }}
      </div>
      <div *ngIf="mspDashboard" routerLink="./msp/dashboard" class="msp-logo">
        <a (click)="handleSaveModal($event, './msp/dashboard')">
          <img src="assets/msp-logo.svg" width="236px" alt="msp-logo">
        </a>
      </div>
    </div>
    <div class="header-actions">
      <div *ngIf="currentUser" class="nav-text">Welcome {{currentUser.name}}</div>
      <clr-dropdown [clrCloseMenuOnItemClick]="false">
        <button clrDropdownTrigger class="dropdown-toggle">
          <img src="assets/icons/gear.svg" />
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <label class="dropdown-header" aria-hidden="true">Settings</label>
          <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="openBugReportModal()">Report a bug</div>
          <div aria-label="Dropdown header Action 2" (click)="logout()" clrDropdownItem>Log Out</div>
          <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
          <button type="button" *ngIf="currTheme=='Dark'" (click)="switchTheme(true)" class="btn btn-icon"
            aria-label="home">
            <cds-icon shape="sun" size="md"></cds-icon>
            Change Theme
          </button>
          <button type="button" *ngIf="currTheme=='White'" (click)="switchTheme(false)" class="btn btn-icon"
            aria-label="home">
            <cds-icon shape="moon" size="md"></cds-icon>
            Change Theme
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </header>
  <div class="content-container">
    <clr-vertical-nav *ngIf="currentUser" id="left-menu" [clrVerticalNavCollapsible]="true"
      [(clrVerticalNavCollapsed)]="collapsed" #navBar>
      <div style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;">
        <div>
          <div [routerLink]="mspDashboard ? './msp/dashboard' : './dashboard'" style="cursor: pointer;">
            <a clrVerticalNavLink (click)="handleSaveModal($event, mspDashboard ? './msp/dashboard' : './dashboard')"
              routerLinkActive="active">
              <cds-icon clrVerticalNavIcon shape="grid-chart"></cds-icon>
              <span class="nav-text">Dashboard</span>
            </a>
          </div>
          <clr-vertical-nav-group *ngIf="mspDashboard" routerLinkActive="active">
            <cds-icon clrVerticalNavIcon shape="users"></cds-icon>
            <span class="nav-text p-0">Customers</span>
            <clr-vertical-nav-group-children style="cursor: pointer;">
              <a clrVerticalNavLink routerLinkActive="active" (click)="handleSaveModal($event, './msp/customers')">
                <cds-icon clrVerticalNavIcon shape="users"></cds-icon>
                <span class="nav-text">All Customers</span>
              </a>
              <a routerLink="./msp/customer-builder" clrVerticalNavLink
                (click)="handleSaveModal($event, './msp/customer-builder')" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="wrench"></cds-icon>
                <span class="nav-text">Create Customer</span>
              </a>
              <a *ngIf="!mspDashboard" routerLink="./gallery" clrVerticalNavLink
                (click)="handleSaveModal($event, './gallery')" routerLinkActive="active">
                <cds-icon id="gallery-link-icon" clrVerticalNavIcon shape="world" badge="info"></cds-icon>
                <span class="nav-text">Application Gallery</span>
              </a>
            </clr-vertical-nav-group-children>
          </clr-vertical-nav-group>
          <div *ngIf="!mspDashboard" routerLink="./configuration/endpoints" style="cursor: pointer;">
            <a clrVerticalNavLink routerLinkActive="active"
              (click)="handleSaveModal($event, './configuration/endpoints')">
              <cds-icon clrVerticalNavIcon shape="computer"></cds-icon>
              <span class="nav-text">Endpoints</span>
            </a>
          </div>
          <div *ngIf="!mspDashboard" routerLink="./configuration/groups" style="cursor: pointer;">
            <a clrVerticalNavLink routerLinkActive="active" (click)="handleSaveModal($event, './configuration/groups')">
              <cds-icon clrVerticalNavIcon shape="devices"></cds-icon>
              <span class="nav-text">Groups</span>
            </a>
          </div>
          <clr-vertical-nav-group routerLinkActive="active">
            <cds-icon clrVerticalNavIcon shape="application"></cds-icon>
            <span class="nav-text p-0">Packages</span>
            <clr-vertical-nav-group-children style="cursor: pointer;">
              <a [routerLink]="!mspDashboard ? './configuration/actions' : './msp/packages'" clrVerticalNavLink
                (click)="handleSaveModal($event, !mspDashboard ? './configuration/actions' : './msp/packages')"
                routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="list"></cds-icon>
                <span class="nav-text">All Packages</span>
              </a>
              <a *ngIf="!mspDashboard" routerLink="./suggested-packages" clrVerticalNavLink routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="asterisk"></cds-icon>
                <span class="nav-text">Suggested Packages</span>
              </a>
              <a [routerLink]="!mspDashboard ? './version-history' : './msp/version-history'" clrVerticalNavLink
                (click)="handleSaveModal($event, !mspDashboard ? './version-history' : './msp/version-history')"
                routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="history"></cds-icon>
                <span class="nav-text">Version History</span>
              </a>
              <a *ngIf="!mspDashboard" routerLink="./recycle-bin" (click)="handleSaveModal($event, './recycle-bin')"
                clrVerticalNavLink routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="recycle"></cds-icon>
                <span class="nav-text">Recycle Bin</span>
              </a>
              <a *ngIf="mspDashboard" routerLink="./msp/recycle-bin"
                (click)="handleSaveModal($event, './msp/recycle-bin')" clrVerticalNavLink routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="recycle"></cds-icon>
                <span class="nav-text">Recycle Bin</span>
              </a>
              <a [routerLink]="mspDashboard ? './msp/package-builder' : './package-builder'" clrVerticalNavLink
                (click)="handleSaveModal($event, mspDashboard ? './msp/package-builder' : './package-builder')"
                routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="wrench"></cds-icon>
                <span class="nav-text">Build Package</span>
              </a>
              <a *ngIf="!mspDashboard" routerLink="./gallery" clrVerticalNavLink
                (click)="handleSaveModal($event, './gallery')" routerLinkActive="active">
                <cds-icon id="gallery-link-icon" clrVerticalNavIcon shape="world" badge="info"></cds-icon>
                <span class="nav-text">Application Gallery</span>
              </a>
            </clr-vertical-nav-group-children>
          </clr-vertical-nav-group>
          <clr-vertical-nav-group *ngIf="!mspDashboard" routerLinkActive="active">
            <cds-icon clrVerticalNavIcon shape="line-chart"></cds-icon>
            <span class="nav-text p-0">Reports</span>
            <clr-vertical-nav-group-children style="cursor: pointer;">
              <a routerLink="./reports/applications" clrVerticalNavLink
                (click)="handleSaveModal($event, './reports/applications')" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="application"></cds-icon>
                <span class="nav-text">Applications</span>
              </a>
            </clr-vertical-nav-group-children>
          </clr-vertical-nav-group>
          <div *ngIf="!mspDashboard" routerLink="./blueprints" style="cursor: pointer;">
            <a clrVerticalNavLink (click)="handleSaveModal($event, './blueprints')" routerLinkActive="active">
              <cds-icon clrVerticalNavIcon shape="applications"></cds-icon>
              <span class="nav-text">Blueprints</span>
            </a>
          </div>
        </div>
        <div *ngIf="!mspDashboard" [hidden]="collapsed" style="text-align: center; font-size: 12px;">
          <nd-download-msi-installer></nd-download-msi-installer>
          <p>{{version}}</p>
        </div>
      </div>
    </clr-vertical-nav>

    <body cds-theme="dark" style="width: 100% !important; overflow-x: hidden;">
      <div class="content-area" style="height: 100% !important;">
        <router-outlet></router-outlet>
      </div>
    </body>
  </div>
</clr-main-container>

<clr-modal [(clrModalOpen)]="openSaveModal">
  <h3 class="modal-title">Unsaved Changes</h3>
  <div class="modal-body">
    <p>There are unsaved changes in your {{saveSvc.currentSave.pageName}}! Do you want to exit your
      {{saveSvc.currentSave.pageName}}?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="openSaveModal = false">No</button>
    <button type="button" class="btn btn-danger" (click)="handleDontSave()">Exit</button>
  </div>
</clr-modal>

<app-notify-me #cmp></app-notify-me>